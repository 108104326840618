'use strict';

angular.module('enervexSalesappApp').controller('IconCtrl', function($scope, Icon, $stateParams, Application, Flash, $q, $state, _, Fan, Voltage, Image, FileUploader, Util) {
	$scope.errors = {};
	$scope.iconTypes = [
		"Appliance",
		"Fan",
	];
	$scope.ctx = {
		formChanged: false
	}
	var uploader = $scope.uploader = new FileUploader({});
	$scope.uploader.onAfterAddingFile = function() {
		$scope.ctx.formChanged = true;
	}
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	if ($stateParams.id == "new") {
		$scope.icon = {
			applications:[],
			asset: {},
			image: '',
			code: '',
			name:'',
			iconType: 'Appliance',
			isDefault: false,
			sortOrder: 0
		}
		$scope.itemCodeDisabled = false
		fetchLookups()
	} else {
		Icon.get({
			id: $stateParams.id
		}).$promise.then(function(icon) {
			$scope.icon = icon;
			fetchLookups()
		})
	}
	function fetchLookups() {
		Application.query({
			limit:3000
		}).$promise.then(function(applications){
			_.each(applications, function(application){
				var existing = _.find($scope.icon.applications, function(o){
					return o._id == application._id
				})
				if (existing){
					application.selected = true
				}
			});
			$scope.applications = applications;
		})
	}

	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			uploadAsset().then(function(image){
				var payload = _.clone($scope.icon);
				payload = _.omit(payload, "__v");
				if (image){
					payload.asset = image._id
				}
				payload.applications = $scope.applications;
				if (!payload._id) {
					Icon.save(payload).$promise.then(function(result) {
						
						$scope.success = true;
						// $scope.fanPhase = result;
						Flash.create('success', '<strong>Success!</strong> Successfully created icon.', 0, {}, true);
						$state.go("icon", {
							id: result._id
						}, {
							inherit: false,
							reload: true
						})
					}).catch(function(err) {
						
						$scope.errors = {};
						$scope.errors.other = 'Problem saving';
						var message = (err.data && err.data.message) || "There was an error saving";
						Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
					})
				} else {
					Icon.update({
						id: payload._id
					}, payload).$promise.then(function(icon) {
						
						$scope.success = true;
						$scope.icon = icon;
						Flash.create('success', '<strong>Success!</strong> Successfully updated icon.', 0, {}, true);
					}).catch(function(err) {
						
						$scope.errors = {};
						$scope.errors.other = 'Problem saving';
						var message = (err.data && err.data.message) || "There was an error saving";
						Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
					})
				}
			})
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};

	function uploadAsset(){
		if ($scope.uploader.queue.length >0){
			var fd = new FormData();
			var data = $scope.uploader.queue[0];
			fd.append('asset', data._file);
			$scope.uploader.clearQueue()
			return Image.save({},fd).$promise;
		} else {
			return $q.resolve(null)
		}

	}

});
